* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
a,
a:hover {
  text-decoration: none;
  color: inherit;
}
p,
a {
  font-family: "Inria Sans", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Playfair Display", serif;
}
.highlights {
  width: 65%;
  padding-right: 40px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  justify-content: center;
  align-items: center;
  background: linear-gradient(178deg, #fff5d2, #fff5d2);
}
.highlights p a {
  color: #333;
  line-height: 30px;
  font-size: 15px;
  padding-left: 15px;
}
.highlights p {
  color: #333;
}
.highlights:after {
  position: absolute;
  content: " ";
  width: 20px;
  height: 60px;
  right: -6px;
  top: -5px;
  background: linear-gradient(120deg, #fff2a3 0%, #ffc35a 86%);
  transform: rotate(13deg);
  z-index: 1;
}
.highlights:before {
  position: absolute;
  content: " ";
  width: 20px;
  height: 60px;
  left: -14px;
  top: -5px;
  background: linear-gradient(126deg, #ffed9b, #ffca65);
  transform: rotate(24deg);
  z-index: 1;
}
.highlights marquee p {
  display: inline-block;
  padding-right: 15px;
}
.topheader {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.topheader p {
  margin-bottom: 0;
}
.topheaderleft img {
  width: 30px;
}
.highlights img {
  width: 30px;
  margin-left: 40px;
  margin-right: 20px;
}
.heading h2 img {
  width: 30px;
  margin-top: 20px;
}
.homemsg .heading h2 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.homemsg {
  padding: 60px 0px 60px 0px;
  background: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    url("https://webapi.entab.info/api/image/GHSS/public/Images/msgbg.jpg")
      no-repeat left center;
}
.messagebox {
  background: url("https://webapi.entab.info/api/image/GHSS/public/Images/msgbox.png")
    no-repeat center;
  padding: 40px 30px 85px 30px;
  width: 75%;
  background-size: 100% 100%;
}
.messageboximg p span {
  color: #333333ad;
  font-weight: 400;
  display: block;
}
.messagebox .maintitle h1 {
  text-align: center;
  margin-bottom: 15px;
}
.messageimg img {
  margin-bottom: 30px;
}
.messageimg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.messageboximg p {
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
}
.homemsg .heading {
  margin-top: 90px;
}
.messageboximg p {
  color: #29335d;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
}
.owl-carousel .item {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.messageimg-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.messageimg {
  width: 280px;
  margin: 0 10px;
  transition: transform 0.5s ease;
}

.messageimg img {
  width: 100%;
  border-radius: 10px;
}

.messageimg.active {
  transform: scale(1.5);
}
.messagedesc p {
  font-size: 18px;
  line-height: 30px;
  color: #666;
}
.messageboximg p {
  font-weight: bold;
}

.testimonials-list {
  display: flex;
}

.testimonial {
  margin-bottom: 25px;
  margin-right: 10px;
  cursor: pointer;
}
.testimonial img {
  border: 6px solid #fff;
  width: 100px;
  height: 100px;
  border-radius: 23%;
  transition: transform 0.3s ease;
}
.testimonial img.active {
  transform: scale(1.5);
  box-shadow: 0px 3px 3px 0px #8b8383;
  border: 7px solid #ffc35a;
}

.testimonial-content {
  transition: transform 0.3s ease; /* Add slide animation */
}

.testimonial-content img {
  width: 150px; /* Adjust size as needed */
  border-radius: 50%;
}

.testimonial-content h3 {
  margin-top: 10px;
}

.testimonial-content p {
  margin-top: 10px;
  text-align: center;
}
.message-container {
  display: flex;
}

.aboutmiddesc p {
  font-size: 18px;
  line-height: 26px;
}
.topheaderleft {
  align-items: center;
  background: linear-gradient(182deg, #fff5a7, #ffb545);
  width: 25%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.topheaderright {
  background: linear-gradient(182deg, #fff5a7, #ffb545);
  width: 10%;
}
.topheaderleft a {
  font-size: 14px;
  line-height: 18px;
  padding: 0px 5px;
}
.topheaderleft span {
  display: block;
  line-height: 0;
}

.topheaderright img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  text-align: center;
  margin-left: 10px;
}

.topheaderright {
  display: flex;
  padding: 1px 0px;
  justify-content: center;
  align-items: center;
}
.topheaderright a {
  border-right: 2px solid #0a3b6824;
}
.topheaderright a:last-child {
  border-right: 0px solid #0a3b6824;
}
.highlights a i {
  background: #666;
  border-radius: 30px;
  color: #fff;
  font-size: 15px;
  padding: 5px 7px;
  line-height: 30px;
}
.highlights a i::before {
  transform: rotate(320deg);
  line-height: 19px;
}
.header {
  display: flex;
  width: 100%;
  position: relative;
  z-index: 9;
  background: linear-gradient(182deg, #2a72b5, #0a3b68);
}
.header-info {
  display: flex;
  width: 18%;
}

.header-info-icn {
  justify-content: center;
  display: flex;
  align-items: center;
}
.header-nav {
  width: 60%;
}
.logo {
  width: 30%;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -70px;
}
nav.navbar.navbar-expand-lg {
  padding: 0;
}

.header-nav .nav-item.active .nav-link:hover {
  color: #fff !important;
}
.header-nav .nav-link {
  color: #fff !important;
  font-size: 16px;
  padding: 37px 0px;
  margin: 0px 3px;
  font-weight: 100;
  text-transform: uppercase;
}
.header-info-icn img {
  padding-top: 20px;
  padding-left: 10px;
}
.header-nav .dropdown-item.active {
  background: #164f84;
  color: #fff;
}
li.nav-item.active {
  background: #ffffff3d;
}
.header-nav .dropdown-menu {
  border-radius: 3px;
  padding: 0;
}
li.nav-item:hover {
  background: #ffffff3d;
}
li.nav-item:hover img {
  filter: invert(1);
}
li.nav-item.active img {
  filter: invert(1);
}
.header-nav .nav-link:hover {
  color: #000 !important;
}

.slider-sec {
  position: relative;
}
.slider-info {
  position: absolute;
  bottom: 50px;
  width: 267px;
  left: 30px;
  padding: 90px 20px 40px 20px;
  color: #fff;
  background: #000;
  border-radius: 150px;
}
.slidercircletext {
  padding-bottom: 50px;
}
.slider-info h4 {
  font-size: 16px;
  line-height: 30px;
  color: #ffed9b;
  font-family: "Inria Sans";
  font-weight: 100 !important;
}
.slider-info-text h5 {
  color: #fcb100;
  text-align: right;
  font-size: 14px;
}
.slider-info .carousel-indicators [data-bs-target] {
  width: 8px !important;
  background-clip: revert;
  border-radius: 40px;
  height: 8px;
  border-bottom: 0px solid #ccc;
  border-top: 0px solid #ccc;
}
.slider-info:before {
  position: absolute;
  content: " ";
  background: url("https://webapi.entab.info/api/image/GHSS/public/Images/thought.png")
    no-repeat !important;
  width: 100%;
  height: 100px;
  top: 5px;
  left: 0;
  background-size: 100% 60% !important;
}
.midsectionbox {
  margin-top: -100px;
  position: relative;
}
.midbox:hover {
  margin-top: -60px;
}
.midbox {
  background: linear-gradient(182deg, #fff5a7, #ffb545);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 20px;
  border-radius: 5px;
  position: absolute;
  width: 23%;
  box-shadow: 0px 5px 4px 0px #00000045;
  transition: all 0.3s ease-in;
}
.midbox:hover .midboxdesc {
  height: 50px;
  opacity: 1;
}
.midbox img {
  margin-bottom: 10px;
  width: 70px;
}
.midbox:hover.midbox h5 {
  margin-top: -20px;
  transform: translateY(-100px);
}
.midbox:hover {
  padding: 60px 20px;
}
.midbox h5 {
  font-size: 20px;
  font-weight: 100;
  line-height: 30px;
  text-transform: uppercase;
  transform: translateY(0px);
  margin-top: 0px;
  transition: all 0.3s ease-in;
}
.midbox h5 span {
  font-weight: bold;
}
.midbox p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: center;
}
.midboxdesc {
  opacity: 0;
  height: 0;
  transition: all 0.2s ease-in;
}
.midsection {
  background: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9)
  );
}

.fulwidth {
  width: calc(90% - 40px);
}
.aboutmidsec h1 {
  font-size: 30px;
  line-height: 40px;
}
.aboutmidsec {
  margin-top: 200px;
  padding: 70px 80px;
  background: url("https://webapi.entab.info/api/image/GHSS/public/Images/about65.png")
    no-repeat center left !important;
}
.maintitle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 0px;
}
.aboutgf {
  width: 90%;
  margin: 0 auto;
  display: block;
}
.aboutmiddesc {
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 30px;
}
.maintitle p {
  letter-spacing: 2.2px;
  font-size: 12px;
  color: #444444;
  text-transform: uppercase;
  margin-bottom: 0;
  font-weight: bold;
  line-height: 10px;
}
.maintitle h1 {
  color: #0a5191;
  font-weight: bold;
  font-size: 25px;
  line-height: 35px;
}
.toopersec {
  position: relative;
  padding: 60px 0px 60px 0px;
  z-index: 9;
  background: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    url("https://webapi.entab.info/api/image/GHSS/public/Images/topperbgleft.jpg")
      no-repeat left center;
  background-size: contain;
}
.toopersec:after {
  position: absolute;
  content: " ";
  background: url("https://webapi.entab.info/api/image/GHSS/public/Images/topperbgright.png")
    no-repeat right center;
  width: 100%;
  height: 40%;
  background-size: contain;
  top: 230px;
  right: 0;
  z-index: -1;
}
.heading img {
  width: 80px;
}
.heading {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.heading h2 {
  -webkit-text-stroke: 2px #2a72b5;
  color: transparent;
  font-size: 45px;
  line-height: 55px;
}
.heading span {
  -webkit-text-fill-color: #2a72b5;
  font-size: 45px;
  font-weight: bold;
  line-height: 55px;
}
.topperbox {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  margin-top: 30px;
}
.toppertaj img {
  width: 40px !important;
}
.topperdiv {
  margin-top: 70px;
  margin-bottom: 60px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.toppertaj {
  position: absolute;
  transition: all 0.3s ease-in;
  z-index: -1;
}
.topperdiv:hover .toppertaj {
  transform: scale(2) rotate(22deg) translateX(15px) translateY(-32px);
}
.topperimages .topprebg {
  border-radius: 80px;
  width: 140px !important;
  height: 140px;
  background: url("https://webapi.entab.info/api/image/GHSS/public/Images/topperbgcir.png")
    no-repeat center;
  margin-bottom: 20px;
  padding: 10px;
}
.topperimages {
  position: relative;
}

.topperdiv:hover .per {
  opacity: 1;
  top: -25px;
  left: 5px;
}
.topperdesc p {
  text-align: center;
  color: #0a3b68;
  font-weight: bold;
  margin-bottom: 4px;
  font-size: 20px;
  line-height: 30px;
}
.tooperpicicn {
  margin-top: -40px;
  width: 145px !important;
}
.topperdesc p span {
  display: block;
  font-weight: normal;
  color: #000;
  font-size: 16px;
}
.per {
  font-weight: bold;
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  transition: all 0.3s ease-in;
  opacity: 0;
}
.homenews .maintitle p {
  letter-spacing: 1.2px;
}
.homenews {
  padding: 60px 0px 60px 0px;
  background: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    url("https://webapi.entab.info/api/image/GHSS/public/Images/eventbg.jpg")
      no-repeat !important;
}

.homenews h2 span {
  display: block;
}
.homenewsboxdesc {
  padding: 15px 0px;
}

.homenewsboxtitle {
  z-index: 9;
  position: relative;
  border-radius: 7px;
  margin-top: 6px;
}
.homenewsboxtitle p {
  color: #000;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px !important;
  padding: 5px 5px;
  text-align: center;
  margin-bottom: 0;
  line-height: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.homenewsboximg img {
  border-radius: 5px;
  height: 310px;
  width: 100% !important;
}
.homenewsboxdesc p {
  font-size: 16px;
  line-height: 28px;
  color: var(--black);
  padding: 0px 10px;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0px;
}
.homenewsboxbottom {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.homenewsbox {
  padding: 10px;
  background: #fff;
  border-radius: 5px;
}
.homenewsbox {
  margin: 0px 15px 20px 0px;
}
.homenewsboxdesc span i {
  color: #1a4872;
}
.eventbg {
  background: url("https://webapi.entab.info/api/image/GHSS/public/Images/eventbg.png")
    no-repeat bottom right;
  padding: 25px;
  background-size: 100% 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: block;
}

.owl-nav .owl-next {
  right: 0;
  display: flex;
  margin-right: 2%;
  font-size: 18px !important;
}
.owl-nav .owl-prev {
  left: 0;
  display: flex;
  margin-left: 2%;
  font-size: 18px !important;
}
.owl-carousel .owl-nav button.owl-prev {
  margin-left: -60px;
}
.owl-carousel .owl-nav button.owl-next {
  margin-right: -60px;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  background: #fff;
  padding: 0px 10px 6px 8px !important;
  border-radius: 10px;
  color: #0a59ac;
  border: 5px solid #0a5191;
}
.owl-theme .owl-nav [class*="owl-"]:hover {
  background: #21588c;
  color: #fff;
  text-decoration: none;

  transition: all 0.5s ease-in;
}
.toopersec .owl-nav.disabled {
  display: none;
}
.maintitle h1 a {
  position: relative;
  z-index: 1;
  float: right;
  background: #fff;
  border: 4px solid #0a56a5;
  border-radius: 13px;
  width: 45px;
  height: 45px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.eventbg marquee {
  margin-bottom: 170px;
  position: relative;
  z-index: 9;
  padding-bottom: 40px;
}
.newsbox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.newsbox a i {
  background: linear-gradient(182deg, #fff5a7, #ffb545);
  border-radius: 30px;
  color: #0a5194;
  font-size: 20px;
  padding: 5px 7px;
  line-height: 30px;
}
.newsbox a i::before {
  transform: rotate(320deg);
  line-height: 25px;
}
.newsdesc h6 {
  font-size: 20px;
  line-height: 30px;
  font-family: "Inria Sans";
  font-weight: bold;
  padding-right: 20px;
  margin-bottom: 0;
}
ul.newsboxlist {
  padding-left: 20px;
}
.newsbg {
  height: 89%;
  padding: 45px;
  position: relative;
  background: url("https://webapi.entab.info/api/image/GHSS/public/Images/news.png")
    no-repeat;
  width: 95%;
  background-size: 100% 100% !important;
}
.newsbg:after {
  position: absolute;
  content: " ";
  width: 100%;
  right: 10px;
  height: 100%;
  bottom: 40px;
  background: url("https://webapi.entab.info/api/image/GHSS/public/Images/newsbg.png")
    no-repeat bottom right;
}
.eventbg .owl-nav {
  padding-bottom: 17px;
}
.newsdesc p {
  font-size: 18px;
  font-weight: 100;
  line-height: 30px;
}

.facilitiesbox h3 {
  font-size: 20px;
  color: #004a80;
  line-height: 30px;
  margin-bottom: 25px;
  margin-top: 35px;
  text-align: center;
  height: 40px;
}

.facilitiesbox p {
  font-size: 17px;
  line-height: 30px;
}

.facilitiesbox img {
  width: 80px;
  margin: 0 auto;
  display: block;
}
.facilitiesbgimg img {
  width: 150px;
  margin-bottom: -110px;
}
.facilities .container-fluid {
  background: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.9),
    90%,
    rgba(255, 255, 255, 0.8) 90%
  );
  background-size: 100% 70% !important;
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}
.facilities {
  padding: 70px 0px 70px 0px;
}
.maintitle.title {
  justify-content: center;
  align-items: center;
}
.maintitle.title p {
  margin-left: -20px;
}
.homegallery {
  padding: 60px 0px 60px 0px;
  background: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url("https://webapi.entab.info/api/image/GHSS/public/Images/gallerybg.jpg")
      no-repeat;
}
.galleryimg {
  background: url("https://webapi.entab.info/api/image/GHSS/public/Images/galbox.png")
    no-repeat;
  margin-bottom: 5px;
  margin-top: 40px;
  padding: 10px;
  background-size: 100% 100%;
  height: 296px;
  margin-right: 10px;
}
.galleryimgbox img {
  min-height: 100%;
  object-fit: cover;
  background: #fff;
}
.gallerydesc {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.7s ease;
  background: linear-gradient(182deg, #fff5a7a6, #ffb545d4);
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.galleryimg:hover .gallerydesc {
  opacity: 1;
}
.galleryimgbox {
  position: relative;
  overflow: hidden;
  height: 250px;
  min-height: 250px;
}

.gallerydesc p {
  font-size: 16px;
  line-height: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.gallerydesc i {
  font-size: 35px;
}

.gallerydesc h6 {
  font-size: 22px;
  font-weight: bold;
  font-family: "Inria Sans";
  line-height: 32px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0;
  margin-top: 20px;
}
.homegallery .owl-nav.disabled {
  display: none;
}
.mainbtn {
  display: flex;
  justify-content: center;
}

.app-cont {
  width: 63%;
}

.app-cont img {
  width: 90px !important;
}

.phone-img img {
  width: 100% !important;
}

.phone-img {
  width: 50%;
}
.footer .d-flex {
  align-items: flex-end;
}
.footer .link {
  color: #000;
  padding: 5px 5px;
  border-radius: 8px;
  background: linear-gradient(182deg, #fff5a7, #ffb545);
  margin-top: 16px;
  width: max-content;
  max-width: 100%;
  font-size: 13px;
  font-weight: bold;
}
.copyright p {
  text-align: center;
  margin-bottom: 0;
  font-size: 13px;
  color: #000;
  font-weight: 500;
}

.footer {
  color: #fff;
  position: relative;
  padding: 20px 0px 0px 0px;
  background: linear-gradient(181deg, #2a72b5 4.78%, #0a3b68 93.85%);
}
.quick-link h3 {
  font-size: 20px;
  line-height: 30px;
  font-family: "Inria Sans";
}
.quick-link li {
  font-weight: 300;
  list-style: none;
  font-size: 20px;
  line-height: 30px;
}

.quick-link ul {
  padding-left: 10px;
}
.app-cont p {
  color: #fff;
  font-family: "Playfair Display", serif;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
}
.copyright {
  background: #fff;
}
.footerform {
  background: #0a3b68;
  padding: 50px 20px 5px 20px;
  margin-top: -35px;
  margin-left: -30px;
  margin-right: 35px;
}
.footerform .form-group {
  margin-bottom: 8px;
}
.footerform .form-group input {
  border: 1px inset #ffb545;
}
.footerform h6 {
  font-size: 20px;
  padding-bottom: 20px;
  line-height: 30px;
  text-align: center;
  font-family: "Inria Sans", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2.5px;
}
.footerform .form-group textarea {
  resize: none;
  min-height: 110px;
  border: 1px inset #ffb545;
}
input[type="Submit"] {
  font-weight: 400;
  background: #fff6;
  color: #134a7d;
  font-size: 15px;
  line-height: 30px;
  padding: 7px 25px;
  text-transform: uppercase;
  border-radius: 6px;
  border: 0px solid #ccc !important;
  display: block;
  text-align: center;
  margin: 20px auto;
}
.footerinfo h6 {
  font-family: "Inria Sans";
  text-transform: uppercase;
  font-size: 16px;
  text-align: center;
  line-height: 30px;
  letter-spacing: 0.5px;
}
.footerinfodiv {
  background: #fff;
  color: #000;
  text-align: center;
  border-radius: 8px;
  padding: 8px 5px;
  width: 274px;
  margin: 0 auto;
}
.footerinfo {
  margin-left: 40px;
  margin-bottom: -13px;
}
.quick-link {
  margin-top: 7px;
}
.footerinfodiv ul {
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  justify-content: center;
}

.footerinfodiv ul li {
  list-style: none;
  position: relative;
  padding: 0px 10px;
}

.footerinfodiv ul li:after {
  position: absolute;
  content: "  | ";
  left: 0;
  color: #ccc;
}
.footerinfodivbottom {
  background: #2a72b5;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 8px 30px;
  width: max-content;
  margin: -5px auto 20px auto;
}
.footerlogo {
  margin-bottom: -25px;
}
.footerinfodivbottom p {
  margin-bottom: 0;
}
.messagedesc {
  display: -webkit-box;
  -webkit-line-clamp: 9;

  margin-bottom: 15px;

  -webkit-box-orient: vertical;
  overflow: hidden;
}
button.navbar-toggler:focus {
  box-shadow: none;
}
.navbar-toggler {
  background-color: #fff;
}
.homenews .maintitle {
  padding: 0px 40px 0px 50px;
}
.eventpd .maintitle {
  padding: 0px 0px 0px 50px;
}
.innerslide {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: linear-gradient(
      360deg,
      #1c1b3b8f 100%,
      #1c1b3b91 100%,
      #3333338c 100%
    ),
    url("https://webapi.entab.info/api/image/GHSS/public/Images/innerslide.jpg")
      no-repeat center center;
  background-size: cover;
  height: 255px;
  padding-bottom: 50px;
}

.innersec {
  min-height: 400px;
  padding: 60px 10px;
}
.innersec p {
  line-height: 34px;
  font-size: 16px;
  color: #4e4e4e;
  font-family: Inter, sans-serif;
}
.innersec h1 {
  font-size: 35px;
  position: relative;
  background: -webkit-linear-gradient(#0f4373, #65aef1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 45px;
  padding-bottom: 0px;
  margin-bottom: 45px;
  padding-left: 15px;
}
.innersec h1:before {
  background: -webkit-linear-gradient(#2a5378, #65aef1);
  content: " ";
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0;
  width: 5px;
}
.innersec h5 {
  font-size: 20px;
  color: #0664a8;
  line-height: 30px;
}
.breadcrumb li:before {
  position: absolute;
  content: " \F138 ";
  width: 100%;
  height: 100%;
  font-family: bootstrap-icons;
  left: -15px;
}
.breadcrumb li:first-child:before {
  content: "\f404";
  background: #cf9705;
  width: 30px;
  border-radius: 20px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -25px;
  top: -3px;
}
.breadcrumb li a {
  color: #f9f9f9;
  position: relative;
}
.breadcrumb li {
  padding: 0px 15px 0px 15px;
  color: #fff;
  position: relative;
  margin-left: 7px;
  font-size: 14px;
}
ul.breadcrumb {
  margin-bottom: 0;
}
.mainimg {
  border: 2px solid #e2c675;
  outline: 2px solid #7ebefa;
  outline-offset: 2px;
  padding: 5px;
  background: #daedff;
}
.principalofficeimg {
  width: 100%;
  height: 200px;
}
.principaloffice {
  overflow: hidden;
  position: relative;
  width: calc(21% - 29px);
  margin: 0px 15px 30px 0px;
  background: #fff;
  box-shadow: 0 0 10px 6px #00000017;
}
.principalofficeimg img {
  object-fit: contain;
  transition: all 0.2s ease-in;
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  background: #e3f1ff61;
  height: 200px;
}
.principalofficedesc {
  text-align: center !important;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 20px 10px 10px 10px;
  width: 100%;
  flex-direction: column;
}
.principalofficedesc h3 {
  font-size: 17px;
  font-weight: 600;
  position: relative;
  padding-bottom: 6px;
  margin-bottom: 10px;
}
.principalofficedesc p {
  margin-bottom: 0;
}
.principalofficedesc h3:after {
  content: " ";
  position: absolute;
  top: 50%;
  width: 100px;
  border-bottom: 1px solid #3971a5;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  bottom: -25px;
}
.principalofficesec {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.innersec h2 {
  font-size: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
  color: #18538a;
}
.managementbox .principaloffice {
  width: 100%;
}
.msgbox {
  float: right;
  margin-bottom: 20px;
  margin-left: 30px;
  position: relative;
  width: 40%;
}
.innersec .name span {
  color: #ff3633;
  display: block;
  font-weight: 400;
}
.innersec .name {
  font-size: 16px;
  font-weight: 700;
}
.msgbox img {
  border: 0px solid #bdbdbd;
  padding: 10px;
  position: relative;
  box-shadow: 0px 2px 10px 1px #ccc;
  border-radius: 5px;
}
.maintable thead th {
  border: 1px solid #ccc;
  background: #1b5891;
  color: #fff;
  vertical-align: middle;
}

.maintable tbody td {
  border: 1px solid #ccc;
}

.maintable tbody tr:nth-child(odd) {
  background: #f1f1f185;
}

.maintable tbody tr:nth-child(even) {
  background: #e8f4ff;
}

ul.mainlist li {
  margin: 10px;
  line-height: 30px;
  font-size: 16px;
  list-style-type: none;
  position: relative;
  color: #464646;
  text-align: justify;
}
ul.mainlist li:before {
  content: "";
  font-size: 20px;
  color: #000;
  left: 0;
  top: 4px;
  vertical-align: middle;
  margin-right: 12px;
  margin-left: -25px;
  position: absolute;
  background-image: url("https://webapi.entab.info/api/image/GHSS/public/Images/list.png");
  background-size: 100% 100%;
  height: 20px;
  width: 20px;
}
ul.mainlist {
  padding-left: 10px;
}
.verticaltabs .nav-pills .nav-link.active {
  background: #286eb0;
  color: #fff;
}
.nav-pills .nav-link {
  background: #e3f1ff;
}
.verticaltabs {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.verticaltabs .tab-content {
  padding: 0px 12px;
  width: 70%;
  border-left: none;
  min-height: 422px;
  padding-top: 20px;
}

.verticaltabs .nav-pills {
  width: 30%;
  min-height: 422px;
}

.verticaltabs .nav-pills .nav-link {
  display: block;
  color: black;
  padding: 13px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 14px;
  line-height: 16px;
  border-bottom: 1px solid #d8d8d8;
  margin: 0px 0px;
  border-radius: 0;
}
.verticaltabs h3 {
  font-weight: 500;
  text-align: center;
  font-size: 29px !important;
  margin-bottom: 15px;
}
.verticaltabs p {
  text-align: center;
  line-height: 45px;
  font-size: 18px;
}
.pray .tab-content {
  background: url("https://webapi.entab.info/api/image/GHSS/public/Images/pray.png")
    no-repeat left top !important;
}
.contact-container {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: -100px;
  width: 90%;
  flex-wrap: wrap;
}
.contact-container > div {
  width: 31%;
  margin-right: 15px;
}
.contact-blk {
  display: flex;
  text-align: left;
  box-shadow: 0 2px 15px rgb(0 0 0 / 16%);
  background: #fff;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}
.contact-icon {
  min-width: 120px;
  display: flex;
  justify-content: center;
  width: 30%;
  background: #104677;
  color: #fff;
  align-items: center;
  min-height: 150px;
}
.contact-icon img {
  filter: invert(1);
  width: 50px;
  height: 50px;
}
.contact-info {
  padding: 25px 15px;
}
.contact-info h6.title {
  color: #000000;
  font-weight: 600;
}
.contact-info p {
  font-size: 14px;
  line-height: 25px;
  text-align: left;
  margin-bottom: 0;
}
.contact-info p a {
  color: #383838;
  font-size: 14px;
}
.contact-info p a:hover {
  color: #970000;
  font-weight: 600;
}

.innersec .topperssec {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
}

.innersec .topperdiv {
  margin: 8px 10px 10px 10px;
  width: calc(20% - 20px);
  background: #e5f2ff;
  box-shadow: 0px 0px 5px 0px #ccc;
  position: relative;
}

.innersec .topperdivimg {
  width: 115px;
  height: 115px;
  margin: 20px auto;
}

.innersec .topperdivimg img {
  width: 100%;
  height: 115px;
  border-radius: 130px;
  border: 2px solid #02749e;
  background: #fff;
  object-fit: contain;
  margin-top: 10px;
}

.innersec .topperdivdesc {
  background: #dceeff;
  padding: 10px 5px;
  width: 100%;
}

.innersec .topperdivdesc p {
  margin-bottom: 0;
  text-align: center;
  font-size: 16px;
}
.innersec .topperdivdesc p span {
  display: block;
  font-size: 15px;
}
.innersec .topperper {
  position: absolute;
  top: -2px;
  background: #245f96;
  width: max-content;
  border-radius: 0px 0px 10px 1px;
  left: 0;
}

.innersec .topperper p {
  margin-bottom: 0;
  padding: 5px 10px;
  color: #fff;
}
.chooseyear {
  margin-bottom: 40px;
}
#chooseddlYear {
  border: unset;
  color: #000;
  height: 30px;
  padding: 0px 0px 0px 4px;
  background: unset;
  font-weight: bold;
  margin-bottom: 15px;
  letter-spacing: 1px;
  width: 6.5em;
  font-size: 15px;
  float: right;
  margin-top: 0;
  border-bottom: 1px solid grey;
}
.chooseyear select {
  margin: 0px;
}
select#chooseddlYear:focus-visible {
  outline: none;
}

.popup {
  padding-right: 0 !important;
}
.popup .carousel-control-prev-icon {
  background: url("https://webapi.entab.info/api/image/GHSS/public/Images/lefticn.png")
    no-repeat !important;
}
.popup .carousel-control-next-icon {
  background: url("https://webapi.entab.info/api/image/GHSS/public/Images/righticn.png")
    no-repeat !important;
}
.popup .carousel-control-next,
.popup .carousel-control-prev {
  width: auto;
}
.popup .carousel-control-next {
  right: -15px;
}
.popup .modal-dialog {
  min-width: 60%;
  transform: translateY(-100px);
}
.modal-header {
  padding: 0 10px;
}
.modal-header h1 {
  color: #fff;
  font-size: 18px;
}
.carousel-item .modal-header {
  background: linear-gradient(182deg, #2a72b5, #0a3b68);
  padding: 13px 0 0;
}
.popup .modal-dialog img {
  width: 100%;
  object-fit: contain;
}
.carousel-item .modal-body h1 {
  padding-bottom: 0;
  text-align: center;
  font-size: 17px;
  color: #000;
  overflow: hidden;
  font-weight: 600;
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
  line-height: 28px;
}
.carousel-item .modal-dialog .modal-header .btn-close {
  color: #fff;
  padding-right: 31px;
}
.carousel-item .modalcard .card-body {
  padding: 10px;
}
.carousel-item .modalcard .card-body p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 35px;
  text-align: center;
  padding-top: 10px;
  line-height: 26px;
}
.carousel-item .modalcard {
  border: none;
}
.popup .modal-content {
  padding: 0 0 10px;
}
.carousel-item button.btn-close:focus {
  box-shadow: none;
}
.carousel-item a.btn.btn-primary.btn-sm {
  background: var(--maroon);
  border: #205b0e;
  margin: 0 10px;
}
.carousel-item a.btn.btn-primary.btn-sm:focus {
  box-shadow: none;
  border: 1px solid #182b3c;
}
.carousel-item button.btn-close {
  position: absolute;
  z-index: 999;
  background-color: #0c2236;
  right: 0;
}
.carousel-item .btn-close {
  background: url("https://webapi.entab.info/api/image/GHSS/public/Images/closeicn.png")
    no-repeat;
  opacity: 1;
  padding: 10px;
  background-position: center;
  border-radius: 0;
}
.modal-bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.erp_sec .guideheading {
  text-align: left;
}

.erp_sec h4 {
  text-align: center;
  line-height: 40px;
  font-size: 17px;
  margin-bottom: 0;
  margin-top: 15px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

.assistance {
  background: rgb(230 243 255);
  padding: 13px;
  border: solid 1px #ddd;
  margin-top: 20px;
}
.erpbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.erpboxdesc {
  width: 72%;
}

.erpboximg {
  width: 26%;
}

.erpboxdescpopup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.erppopup {
  width: 30%;
  margin: 10px;
}

.imgheading a {
  color: #20488a;
  text-align: center;
  width: 100%;
  display: block;
  font-size: 20px;
  padding: 10px 0;
}

.erppopup .modal-body h5 {
  color: #000;
  text-align: center;
  padding-top: 20px;
}

#myImg,
#myImg1,
#myImg2 {
  width: 100%;
  border: 5px solid rgb(191 185 188);
  background: #cbcaca;
  cursor: pointer;
  transition: 0.3s;
}
.modal {
  background: #0000008a;
  z-index: 99999;
  padding-right: 0 !important;
}
h3.Subhead {
  color: #104677;
  font-size: 20px;
}

.coming_soon {
  width: 400px !important;
  margin: auto !important;
  display: block !important;
  height: auto !important;
}

.magnify-modal {
  position: fixed !important;
  z-index: 999999999 !important;
  padding-top: 10px !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: auto !important;
  background-color: rgba(0, 0, 0, 0.8509803922) !important;
}

.magnify-header .magnify-toolbar {
  float: right;
  position: relative;
  z-index: 9;
}

.magnify-stage {
  position: absolute;
  top: 40px;
  right: 10px;
  bottom: 40px;
  left: 10px;
  z-index: 1;
  background: #0000000a;
  overflow: hidden;
}

.magnify-image {
  position: relative;
  display: inline-block;
  border: 5px solid #fff;
}

.magnify-image.image-ready {
  max-width: 100%;
  max-height: 100%;
}

.magnify-footer {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  color: #fff;
  text-align: center;
}

.magnify-footer .magnify-toolbar {
  display: inline-block;
}

.magnify-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  background: #055a91;
}

.magnify-button:hover {
  color: #fff;
  background-color: #e00000;
}

.magnify-button-close:hover {
  background-color: #e00000;
}

.magnify-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: #333;
}

.magnify-loader::before {
  content: "";
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5)
    rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  -webkit-animation: magnifyLoading 1s infinite linear;
  animation: magnifyLoading 1s infinite linear;
}

.magnify-loader::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  overflow: hidden;
}

@-webkit-keyframes magnifyLoading {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }

  100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
    transform: rotateZ(360deg) translate3d(0, 0, 0);
  }
}

@keyframes magnifyLoading {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }

  100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
    transform: rotateZ(360deg) translate3d(0, 0, 0);
  }
}
.chooseyear {
  margin-bottom: 40px;
}
#chooseddlYear {
  border: unset;
  color: #000;
  height: 30px;
  padding: 0px 0px 0px 4px;
  background: unset;
  font-weight: bold;
  margin-bottom: 15px;
  letter-spacing: 1px;
  width: 6.5em;
  font-size: 15px;
  float: right;
  margin-top: 30px;
  border-bottom: 1px solid grey;
}

#chooseddlYear option {
  color: black;
}
.chooseyear select {
  margin: 0px;
}
.chooseyear select {
  width: 100%;
  background: unset;
  border: unset;
  height: 28px;
  color: #fff;
  margin-top: 15px;
  border: unset;
  border-bottom: 1px solid grey;
}
.clr {
  clear: both;
}
.video-gallery {
  padding: 20px;
  box-shadow: 0px 1px 4px 1px #03030330;
  margin-bottom: 20px;
  background: #e2edfc;
  border-radius: 3px;
}
.video-gallery a {
  display: flex;
  color: inherit;
  align-items: center;
}
.video-gallery a i {
  font-size: 40px;
  color: #ad0807;
  margin-right: 10px;
  display: flex;
}
.video-gallery a h3 {
  font-size: 16px;
  margin-bottom: 0;
  line-height: 24px;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: 0;
  padding-left: 0;
}
.video-gallery p {
  font-size: 12px;
  margin-bottom: 0;
}
.video-gallery a h3:after {
  position: static;
}
.video-gallery a img {
  width: 52px;
  margin-right: 10px;
}

.event-img {
  position: relative;
  margin-bottom: 30px;
  background: #e5e5e5;
}
.event-img img {
  height: 200px;
  object-fit: contain;
  width: auto;
  margin: auto;
  display: block;
  position: relative;
}
.event-content p {
  line-height: 28px;
  margin-bottom: 0;
  height: 100px;
  overflow-y: scroll;
}

.event-blk {
  height: 100%;
  box-shadow: 0px 0px 5px 0px #ddd;
  position: relative;
}
.event-blk:hover {
  transition-duration: 0.5s;
  box-shadow: -9px 8px 17px 5px #efefef;
  filter: grayscale(1);
}
.event-content {
  padding: 0 20px;
}
.event-time {
  position: absolute;
  right: 0;
  bottom: 0;
}
.event-time p {
  margin-bottom: 0;
  background: #e9f2ff;
  padding: 1px 9px;
  box-shadow: -1px 0px 5px 3px #ffffff87;
  border-radius: 14px 0 0 0;
  font-size: 14px;
}
.landmark {
  display: flex;
  justify-content: space-between;
  padding: 0 16px 10px;
}
.landmark p {
  font-size: 15px;
  margin-bottom: 0;
  padding-bottom: 0;
}
.eventCount {
  margin-bottom: 30px;
}
.event-date {
  padding: 0 20px;
}
.event-blk .attachment {
  padding: 0;
  width: 30px;
  background: #fff;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.event-blk .attachment a {
  color: #022939;
}

.event-date h6 {
  color: #747474;
  margin-bottom: 10px;
}

.event-blk .landmark span {
  color: black;
  font-weight: 600;
}
a {
  color: inherit;
  text-decoration: none;
}
.event-content,
.landmark {
  padding: 0px 20px;
}
.landmark {
  margin-bottom: 20px;
}

.event-content h3 {
  margin-bottom: 13px;
  font-size: 20px;
}

.event-blk .attachment {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #0f4e8c;
  color: #fff;
  padding: 2px 5px;
}
.attachment {
  color: #da2128;
  text-align: right;
}
.attachment a i {
  color: #fff;
}
.event-date {
  position: absolute;
  bottom: 0;
  left: -5px;
  z-index: 9;
}
.event-date h3 {
  font-size: 16px;
  margin-bottom: -15px;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #ffffff;
  background: #05113f;
  padding: 10px 15px;
  /* border-radius: 5px; */
  box-shadow: 4px 7px 5px 0px #00000087;
  font-family: "Roboto", savbps-serif;
  text-align: center;
  border-radius: 10px;
}
.event-time {
  position: absolute;
  right: 0;
  bottom: 0;
}

.row.tabs-dynamic {
  background: #cbdcf1;
  padding: 20px;
  margin: 0px 0px 40px 0px;
}
.row.tabs-dynamic p {
  margin-bottom: 0;
}
.row.tabs-dynamic p a {
  color: #585757;
}
.row.tabs-dynamic select {
  width: 100%;
  border: 0px;
  background: transparent;
  padding: 8px 5px;
  border-bottom: 1px solid #b6b6b6;
}
.row.tabs-dynamic input {
  padding: 9px 5px;
  width: 100%;
  border: 0px;
  border-bottom: 1px solid #b6b6b6;
  background: transparent;
}
.count-val p {
  margin-bottom: 0px;
}
.load-more .next {
  background: #273c5b;
  width: max-content;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
  margin: auto;
  margin-top: 20px;
  cursor: pointer;
}

.news-head {
  display: flex;
  align-items: center;
}
.news-head h2 {
  padding-right: 20px;
  border-right: 1px solid #c5c5c5;
  margin-right: 20px;
  background: #e5e5e5;
  padding: 4px 10px;
}
.news-head h3 {
  font-weight: 400;
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 14px;
  color: #00599b;
  position: relative;
}
.news-blk {
  margin-bottom: 20px;
  padding-right: 30px;
  padding-bottom: 30px;
  width: 97%;
  box-shadow: 9px 11px 5px 0px #dddddd3d;
  position: relative;
  overflow: hidden;
  padding: 20px;
  box-shadow: 0px 0px 12px 0px #e7e7e7;
  border: 4px double #b5b5b5;
  transition: 0.5s;
}
.news-blk:before {
  content: "";
  position: absolute;
  top: -40px;
  right: -8px;
  width: 100px;
  height: 100px;
  background: #ecf9ff;
  border-radius: 50%;
}
.attachment {
  background: #00bcd4;
  text-align: right;
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 5px;
}
.news-content p {
  margin-bottom: 6px;
  max-height: 160px;
  overflow-y: scroll;
}
.news-content p::-webkit-scrollbar {
  width: 0;
}
.news-blk:hover {
  box-shadow: rgb(0 0 0 / 5%) 0px 2px 1px, rgb(0 0 0 / 5%) 0px 4px 2px,
    rgb(0 0 0 / 5%) 0px 8px 4px, rgb(0 0 0 / 5%) 0px 16px 8px,
    rgb(0 0 0 / 5%) 0px 32px 16px;
}
.news-head h2 span {
  display: block;
  color: #153168;
  font-size: 18px;
  text-align: center;
}
.newsCount:nth-child(4n + 1) > div {
  border-left: 3px solid #fff27e;
}
.newsCount:nth-child(4n + 2) > div {
  border-left: 3px solid #ceb2ff;
}
.newsCount:nth-child(4n + 3) > div {
  border-left: 3px solid #ff928f;
}
.newsCount:nth-child(4n + 4) > div {
  border-left: 3px solid #ffb470;
}

.gallery-inner {
  background: #f1f1f1;
  margin-bottom: 30px;
  box-shadow: 7px 6px 12px -4px #ddd;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-in;
}
.gallery-blk a {
  color: inherit;
}
.gallery-content {
  padding: 20px 15px;
  position: relative;
  /* bottom: -105px; */
  background: #fff;
  transition: all 0.5s ease-in;
}

.gallery-blk img {
  width: auto;
  max-width: 100%;
  height: 100%;
  margin: auto;
  display: block;
  position: relative;
  box-shadow: 0px 0px 19px 0px #00000054;
  border: 7px solid #ffffff4f;
  cursor: pointer;
}

.gallery-inner:hover .gallery-content {
  bottom: 0;
}
.gallery-blk {
  height: 230px;
  object-fit: contain;
  position: relative;
  background: #ddd;
  overflow: hidden;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub-gallerycount .gallery-blk img {
  height: 100%;
}
.sub-gallerycount .gallery-blk {
  height: 200px;
  margin-bottom: 20px;
}
.gallery-blk .gallery-title h3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.gallery-title h3 {
  font-size: 18px;
  color: #000000b8;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  padding: 0px;
  margin-bottom: 15px;
}
a.view-more {
  color: #c99536;
  font-size: 14px;
}
.gallery-title p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  line-height: 28px !important;
  font-size: 16px !important;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}
.gallery-date {
  position: absolute;
  top: -29px;
  display: flex;
  right: 0;
  background: #273c5b;
  width: 75px;
  height: 60px;
  /* border-radius: 50px; */
  padding: 10px;
  justify-content: center;
  align-items: center;
}
.gallery-date:after {
  content: "";
  position: absolute;
  top: 8px;
  left: -22px;
  background: #273c5b;
  width: 42px;
  height: 42px;
  transform: rotate(45deg);
}
.gallery-date h6 span {
  font-size: 14px;
  font-weight: normal;
}
.gallery-date h6 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.gallery-date h6 {
  color: #fff;
  font-size: 21px;
  margin-bottom: 0;
  font-weight: bold;
  z-index: 9;
  line-height: 28px;
}
.gall-num h6 {
  font-family: "Inria Sans";
  font-size: 13px;
  color: #000000a6;
}
.subgalleryimg img {
  box-shadow: 0px 0px 19px 0px #00000054;
  border: 7px solid #ffffff4f;
  width: calc(34% - 20px);
  margin: 0px 0px 10px 10px;
}

.subgalleryimg {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}
h4.gallery-title {
  color: #000000bf;
  font-size: 18px;
  line-height: 30px;
}
.sub-gallerycount img {
  border: 3px double #ccc;
  background: #f1f1f1;
  height: 200px;
  width: 100%;
  object-fit: cover;
  margin-bottom: 20px;
  padding: 4px;
}
.newsbg marquee {
  position: relative;
  z-index: 9;
  height: 300px;
}
.campuslogo {
  position: fixed;
  bottom: 140px;
  right: 0;
  z-index: 9;
}
span.bounce1 img {
  width: 22px;
}
.OnlineR a {
  color: #fff;
}
a.parantlogo {
  padding: 2px 10px;
}
.OnlineR {
  position: fixed;
  right: 0;
  z-index: 99;
  text-align: center;
  color: #fff;
  top: 40%;
  font-family: Open Sans, sans-serif;
}
@keyframes bounce1 {
  0% {
    background-color: #2367a5;
  }
  33% {
    background-color: #ffa207;
  }
  66% {
    background-color: #2367a5;
  }
  100% {
    background-color: #ffac10;
  }
}
.bounce1 {
  border-radius: 25px 0px 0px 25px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  animation: bounce1 1s infinite;
  padding: 10px 12px;
}

.popup {
  padding-right: 0 !important;
}
.popup .carousel-control-prev-icon {
  background: url("https://webapi.entab.info/api/image/GHSS/public/Images/lefticn.png")
    no-repeat !important;
}
.popup .carousel-control-next-icon {
  background: url("https://webapi.entab.info/api/image/GHSS/public/Images/righticn.png")
    no-repeat !important;
}
.popup .carousel-control-next,
.popup .carousel-control-prev {
  width: auto;
}
.popup .carousel-control-next {
  right: -15px;
}
.popup .modal-dialog {
  min-width: 60%;
  transform: translateY(-100px);
}
.carousel-item .modal-header {
  background: #0a4685;
  padding: 13px 10px 0;
}
.popup .modal-dialog img {
  width: 100%;
  object-fit: contain;
}
.carousel-item .modal-body h1 {
  padding-bottom: 0;
  text-align: center;
  font-size: 17px;
  color: #000;
  overflow: hidden;
  font-weight: 600;
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
  line-height: 28px;
}
.carousel-item .modal-dialog .modal-header .btn-close {
  color: #fff;
  padding-right: 31px;
}
.carousel-item .modalcard .card-body {
  padding: 10px;
}
.carousel-item .modalcard .card-body p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 35px;
  text-align: center;
  padding-top: 10px;
  line-height: 26px;
}
.carousel-item .modalcard {
  border: none;
}
.popup .modal-content {
  padding: 0 0 10px;
}
.carousel-item button.btn-close:focus {
  box-shadow: none;
}
.carousel-item a.btn.btn-primary.btn-sm {
  background: var(--maroon);
  border: #205b0e;
  margin: 0 10px;
}
.carousel-item a.btn.btn-primary.btn-sm:focus {
  box-shadow: none;
  border: 1px solid #182b3c;
}
.carousel-item button.btn-close {
  position: absolute;
  z-index: 999;
  background-color: #0c2236;
  right: 0;
}
.carousel-item .btn-close {
  background: url("https://webapi.entab.info/api/image/GHSS/public/Images/closeicn.png")
    no-repeat;
  opacity: 1;
  padding: 10px;
  background-position: center;
  border-radius: 0;
}
.modal-bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.erp .modal-header {
  padding: 10px 10px;
}
.kinerslider p {
  font-size: 16px;
  line-height: 30px;
}
.kindericnimg {
  position: absolute;
  bottom: 40px;
  left: 30px;
  animation: upanimation 5s linear infinite;
}
.erp h5 {
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  margin-bottom: 0;
  color: #333;
}
.kinersliderimg {
  animation: upanimation 5s linear infinite;
}
.kindersliderimg3 {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -110%);
}
.kindersliderimg2 {
  position: absolute;
  bottom: 50%;
  left: 100%;
  transform: translate(-50%, -100%);
}
.kinerslider h3 {
  font-family: cursive;
  color: #1d5c96;
  font-size: 30px;
  line-height: 40px;
  padding-bottom: 10px;
}
.kinderinfo {
  padding: 50px 0px;
  background: #f7f9ff;
  position: relative;
}
.kinderinfotop {
  transition: all 0.3s ease-out 0s;
  position: absolute;
  top: 100px;
  right: 140px;
  animation: upanimation 5s linear infinite;
}
.kinderinfotop1 {
  transition: all 0.3s ease-out 0s;
  position: absolute;
  bottom: 100px;
  right: 140px;
  animation: upanimation 5s linear infinite;
}
@keyframes upanimation {
  0% {
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    transform: rotateX(0deg) translateY(-20px);
  }
  100% {
    transform: rotateX(0deg) translateY(0px);
  }
}
.kinderinfo button.accordion-button {
  border-radius: 50px;
  border: 0px solid #ccc;
  margin-bottom: 20px;
  background: #2468a7;
  color: #fff;
}
.kinderinfo .accordion-button:focus {
  box-shadow: none;
}
.kinderinfo .accordion-item {
  border: 0px solid #ccc;
}
.kinderinfo .accordion-item:first-of-type .accordion-button {
  border-radius: 40px !important;
}
.kinderinfo .accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 40px !important;
}
.kinderinfo .accordion-button::after {
  filter: invert(1);
}
.kinderinfo .accordion {
  margin-top: 60px;
  margin-bottom: 60px;
}
.kinderinfo ul li {
  list-style: none;
  position: relative;
  line-height: 30px;
}
.kinderinfo ul li:before {
  position: absolute;
  content: " ";
  width: 10px;
  height: 10px;
  background: #fe4b7b;
  top: 10px;
  left: -20px;
  border-radius: 20px;
  box-shadow: 0px 0px 1px 1px #fe4b7b;
}
.kinderinfo p {
  line-height: 30px;
}
.kinderuniform h5 {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 30px;
  color: #fff;
  width: max-content;
}
.kinderuniform p {
  color: #fff;
}
.kinderuniform h5:before,
.kinderdesc h4:before {
  content: "";
  position: absolute;
  -webkit-mask-image: url("https://webapi.entab.info/api/image/GHSS/public/Images/bg.svg");
  -webkit-mask-repeat: repeat-x;
  -webkit-mask-size: auto;
  background-color: #fe4b7b;
  width: 100%;
  height: 6px;
  margin: auto;
  bottom: 0;
  right: 0;
  opacity: 1;
}
.kinderuniform {
  position: relative;
  padding: 100px 0px;
}
.kinderhours {
  position: relative;
  z-index: 9;
}
.kinderuniform:before {
  position: absolute;
  content: "";
  width: 100%;
  bottom: 0;
  left: 0;
  height: 100%;
  background: url("https://webapi.entab.info/api/image/GHSS/public/Images/uniformbg.png")
    no-repeat;
  transform: rotate(180deg);
}
.kinderuniform:after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  width: 100%;
  height: 60%;
  background: url("https://webapi.entab.info/api/image/GHSS/public/Images/uniformbg1.png")
    no-repeat;
  transform: rotate(180deg);
  opacity: 0.8;
}

.kinderhours {
  position: relative;
  background: url("https://webapi.entab.info/api/image/GHSS/public/Images/uniformbg.png")
    no-repeat;
  padding: 100px 0px 0px 0px;
  margin-bottom: -32px;
}
.kinderuniinfo {
  position: relative;
  z-index: 9;
}
.kinderhoursbox {
  display: flex;
  background: #fff;
  border-radius: 5px;
  padding: 30px 20px;
  margin-top: 30px;
  box-shadow: 0px 0px 6px 3px #1112133d;
}
.kinderuniinfo .maintitle h1 {
  color: #fff;
  text-align: center;
  margin-bottom: 60px;
}
.kinderdesc {
  width: 100%;
}
.kinderhours p {
  color: #000;
}
.kinderimg {
  width: 100px;
  height: 100px;
  margin-right: 25px;
}
.kinderdesc h4 {
  font-size: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
  width: max-content;
}
.kinerslider {
  position: relative;
  padding: 200px 0px;
  background: url("https://webapi.entab.info/api/image/GHSS/public/Images/kinderslider.png");
}
.kinerslider:before {
  position: absolute;
  width: 40%;
  height: 100%;
  right: 0;
  top: 0;
  content: " ";
  background: url("https://webapi.entab.info/api/image/GHSS/public/Images/main.png")
    no-repeat;
  background-size: 100%;
}
.error {
  color: red;
  font-size: 0.875rem;
}

.success-message {
  color: #9ae39a;
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;
}

.innersec.cabinet .topperdivimg {
  width: 100%;
  height: auto;
  margin: 0;
}

.innersec.cabinet .topperdivimg img {
  border-radius: unset;
  border: 0px;
}

.innersec.cabinet p.toppername span {
  color: #cf9705;
  text-transform: uppercase;
}

.innersec.cabinet p.toppername {
  font-weight: 600;
}

.innersec.cabinet .topperdiv {
  border: 1px solid #02749e;
  border-radius: 5px;
  overflow: hidden;
  justify-content: flex-start;
  padding: 9px;
}
.innersec.cabinet .topperdivdesc {
  background: transparent;
}
ul.sitemapli {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 5px;
  padding-left: 15px;
}

.sitemapli li {
  width: calc(27% - 26px);
  margin-bottom: 5px;
  position: relative;
  list-style: none;
  font-size: 16px;
  line-height: 30px;
  padding-left: 20px;
  color: #333c;
}

h3.subheading {
  font-size: 18px;
  font-family: "Inter";
  line-height: 30px;
  margin-top: 10px;
  color: #000;
}

.sitemapli li:after {
  position: absolute;
  content: " ";
  width: 8px;
  height: 8px;
  background: #2a72b4;
  border-radius: 50px;
  left: 0;
  bottom: 10px;
}

@media (max-width: 1668px) {
  .facilitiesbox h3 {
    height: 80px;
  }
}
@media (max-width: 1600px) {
  .fulwidth {
    width: calc(100% - 26px);
  }
  .topheaderleft {
    width: 30%;
  }
  .highlights {
    width: 60%;
  }
  .header-nav .nav-link {
    font-size: 13px;
    margin: 0px 0px;
  }
  .header-info {
    width: 20%;
  }
  .header-nav {
    width: 51%;
  }
  .header-info-icn a {
    padding: 7px 9px;
  }
  .slider-info {
    bottom: 120px;
  }
}
@media (max-width: 1400px) {
  .sitemapli li {
    width: calc(35% - 26px);
  }
  .popup .modal-dialog {
    min-width: 84%;
  }
  .contact-container {
    width: 95%;
  }
  .contact-icon {
    min-width: 90px;
  }
  .aboutgf {
    margin-left: auto !important;
    margin-right: 10px;
    width: 80%;
  }
  .toopersec:after {
    height: 25%;
    top: 300px;
  }
  .footerform .form-group textarea {
    min-height: 70px;
  }
  .app-cont p {
    font-size: 12px;
  }
  .app-cont img {
    width: 70px !important;
  }
  .quick-link li {
    font-size: 16px;
  }
  .app-cont {
    width: 50%;
  }
  .footerform {
    margin-left: 0;
    margin-right: 0;
  }
  .footer .link {
    font-size: 9px;
  }
}
@media (max-width: 1348px) {
  .footerinfodiv {
    width: 100%;
  }
  .header-nav .nav-link {
    font-size: 12px;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .highlights {
    width: 55%;
  }
  .topheaderleft {
    width: 35%;
  }
  .logo {
    padding-right: 5px;
    padding-left: 5px;
  }
  .header-info {
    width: 20%;
  }
  .header-info-icn a {
    font-size: 12px;
  }
}
@media (max-width: 1200px) {
  .sitemapli li {
    width: calc(51% - 26px);
  }
  .innersec .topperdiv {
    width: calc(33% - 20px);
  }
  .contact-container > div {
    width: 46%;
  }
  .principaloffice {
    width: calc(34% - 29px);
  }
  .newsbg {
    width: 100%;
  }
  .testimonial {
    margin-right: 40px;
  }
  .aboutgf {
    width: 86%;
  }
  .messageimg {
    width: 100%;
  }

  .messageimg {
    flex-direction: row;
  }
  .message-container {
    flex-wrap: wrap;
  }
  .messagebox {
    width: 100%;
  }
  .highlights {
    width: 100%;
    order: 2;
  }
  .topheaderleft span {
    display: inline-block;
  }
  .topheaderleft {
    width: 80%;
    justify-content: flex-start;
    padding-left: 20px;
    background: none !important;
  }
  .topheaderright {
    width: 20%;
    padding: 1px 20px;
    justify-content: flex-end;
    background: none !important;
  }
  .topheader {
    background: linear-gradient(182deg, #fff5a7, #ffb545);
  }
  .highlights:before {
    background: none;
  }
  .highlights:after {
    background: none;
  }
  .header {
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
  .logo {
    width: 100%;
    margin-bottom: -20px;
    margin-top: 20px;
  }
  .header-nav {
    width: 76%;
  }
  .header-nav .nav-link {
    font-size: 14px;
    padding: 15px 0px;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .midbox h5 {
    font-size: 15px;
  }
  .phone-img {
    width: 10%;
  }
  .app-cont {
    text-align: center;
    width: 30%;
  }
  .footer .d-flex {
    margin-top: 20px;
    justify-content: center;
  }
  .footer .link {
    width: 100%;
  }
  .app-cont img {
    width: 140px !important;
  }
  .app-cont p {
    font-size: 16px;
  }
  .footer .link {
    font-size: 14px;
  }
  .footerform {
    margin-top: 20px;
  }
}
@media (max-width: 991px) {
  .sitemapli li {
    width: calc(53% - 26px);
  }
  .popup .modal-dialog {
    min-width: 95%;
  }
  .contact-container {
    width: 100%;
  }

  .principaloffice {
    width: calc(52% - 29px);
  }
  .msgbox {
    width: 50%;
  }
  .aboutmidsec {
    margin-top: 0;
    background: url("https://webapi.entab.info/api/image/GHSS/public/Images/about65.png")
      no-repeat left top !important;
  }

  nav.navbar.navbar-expand-lg {
    display: flex;
    justify-content: flex-end;
    padding-left: 0;
    padding-right: 10px;
    width: 100%;
  }
  .aboutgf {
    width: 80%;
    margin: 0 auto;
    display: block;
  }

  .logo {
    width: 50%;
  }
  .header-info {
    width: 50%;
    justify-content: end;
  }
  .header-nav {
    width: 100%;
    order: 3;
  }
  .slider-info {
    position: relative;
    bottom: 0px;
    left: 0;
    margin: 30px auto;
  }
  .midsectionbox {
    margin-top: 40px;
    position: static;
  }
  .midbox {
    position: static;
    width: 100%;
    margin-bottom: 20px;
  }
  .messageimg {
    flex-direction: row;
  }
  .app-cont {
    width: 50%;
  }
  .phone-img {
    width: 20%;
  }
  .topheaderright {
    display: flex;
  }
  .newsbg {
    height: 460px;
  }
}

@media (max-width: 767px) {
  .sitemapli li {
    width: 100%;
  }
  .innersec .topperdiv {
    width: calc(50% - 20px);
  }
  .kinerslider:before {
    background: url("https://webapi.entab.info/api/image/GHSS/public/Images/main.png")
      no-repeat bottom;
    background-size: contain;
  }
  .kinderhours {
    background: #175186;
    padding: 60px 0px 60px 0px;
  }
  .kinderuniform {
    padding: 0px 0px 100px 0px;
  }
  .phone-img img {
    width: 50% !important;
    margin: 0 auto;
    text-align: center;
    display: block;
  }
  .kinerslider {
    padding: 120px 0px 260px 0px;
  }
  .verticaltabs .nav-pills {
    width: 100%;
    min-height: auto;
  }
  .verticaltabs .tab-content {
    width: 100%;
  }
  .erpboximg {
    width: 100%;
  }

  .erpboxdesc {
    width: 100%;
  }
  .contact-container {
    margin-top: 20px;
  }
  .contact-container > div {
    width: 100%;
  }
  .msgbox {
    margin-top: 20px;
    width: 100%;
    float: none;
    margin-left: 0px;
  }
  .toopersec {
    padding: 0px 0px 40px 0px;
  }
  .facilities {
    padding: 0px 0px 40px 0px;
  }
  .homenews {
    padding: 60px 0px 40px 0px;
  }
  .homemsg {
    padding: 40px 0px 40px 0px;
  }
  .homemsg .heading {
    margin-top: 0;
  }
  .aboutmidsec {
    padding: 40px 80px;
  }
  .topheaderright {
    width: 100%;
    justify-content: center;
  }
  .topheaderleft,
  .topheaderright {
    width: 100%;
  }
  .highlights {
    width: 100%;
  }
  .facilitiesbox h3 {
    height: auto;
  }
}
@media (max-width: 567px) {
  .innersec .topperdiv {
    width: calc(100% - 20px);
  }
  .kinderhoursbox {
    flex-wrap: wrap;
  }
  .kinerslider:before {
    background: none;
  }
  .kinderhoursbox {
    justify-content: center;
  }
  .kindersliderimg3 {
    top: -80px;
    left: 0;
    transform: inherit;
  }
  .kinderdesc {
    text-align: center;
  }
  .kinderdesc h4 {
    text-align: center;
    width: 100%;
  }
  .kinderimg {
    margin-bottom: 20px;
  }
  .kindersliderimg2 {
    left: 280px;
  }
  .kinerslider {
    padding: 120px 0px 150px 0px;
  }
  .kinderuniform {
    background: #175186;
  }
  .principaloffice {
    width: 100%;
    margin-right: 0;
  }
  .footerinfo {
    margin-left: 0;
    margin-bottom: 0;
  }
  .footerlogo {
    margin-bottom: 0;
  }
  .testimonial {
    margin-right: 25px;
  }
  .testimonial img {
    width: 75px;
    height: 75px;
  }
  .fulwidth {
    width: 100%;
  }
  .maintitle h1 {
    font-size: 25px;
    line-height: 35px;
  }
  .midbox:hover {
    margin-top: 0;
  }
  .topperdiv {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .header-info {
    width: 100%;

    justify-content: center;
    margin-top: 15px;
  }
  .logo {
    width: 70%;
  }
  .homenewsboximg img {
    height: auto;
  }
  .phone-img {
    width: 80%;
  }
  .footer .d-flex {
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .app-cont {
    width: 100%;
  }
  .newsbg marquee {
    margin-bottom: 80px;
    height: 330px;
    overflow: hidden;
  }
  .aboutmidsec {
    padding: 175px 10px 40px 10px;
  }
  .newsbg {
    height: auto;
  }
  .heading h2 {
    font-size: 25px;
    line-height: 35px;
  }
}
